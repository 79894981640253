<template>
  <div class="shop-personal-index">
    <div class="shop-persona-head">
      <shop-header type="other"></shop-header>
      <ShopTabList :list="tabList"></ShopTabList>
    </div>
    <router-view v-slot="{ Component }">
      <transition name="scale" mode="out-in">
        <div :key="route.path" class="box">
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import ShopTabList from "@views/shop/components/tabList/index.vue";
import {useRoute} from "vue-router";


const {proxy} = getCurrentInstance();
const route = useRoute()
const tabList = ref([
  {
    name: "个人中心",
    id: 1,
    jumpUrl: '/shop/person/index'
  },
  {
    name: "订单管理",
    id: 2,
    jumpUrl: '/shop/person/order'
  },
  {
    name: "地址管理",
    id: 3,
    jumpUrl: '/shop/person/address'
  },
])
</script>
<script>
import {defineComponent} from "vue";
import personInfo from "./components/personInfo.vue";

export default defineComponent({
  components: { personInfo },
  name: "shopPersonalIndex",
});
</script>
<style lang="scss" src='./index.scss' scoped>
</style>