<template>
    <div class="container shop-tab-list">
      <div v-for="(item,index) in list" :key="item.id || index" class="item" :class="tabChecked === index?'active':''" @click="tabCheck(index)">{{item.name}}</div>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  list:{
    type: Array,
    default: () => []
  }
})
const {list} = toRefs(props);
const router = useRouter();
const route = useRoute();
const {proxy} = getCurrentInstance();
const tabChecked = computed(() => {
  return list.value.findIndex(item => item.jumpUrl === route.path)
})
const tabCheck = (e)=>{
  router.push({
    path: list.value[e].jumpUrl
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopTabList",
});
</script>
<style lang="scss"  scoped>
.shop-tab-list{
  display: flex;
  align-items: center;
  background-color: #293245;
  padding-block: 26px;
  .item{
      cursor: pointer;
      transition: all 0.5s linear;
        font-size: 24px;
        color: #697691;
      &:not(:last-child){
        margin-right: 176px;
      }
      &.active{
        color: #fff;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          bottom: -26px;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 6px;
          background:$mainColor;
          border-radius: 3px;
        }
      }
    }
}
</style>